import React from 'react'
import {graphql, useStaticQuery, Link} from 'gatsby'
import Img from 'gatsby-image'


const Blog = (props) => {

    const data = useStaticQuery(graphql`
      query{
        allMarkdownRemark(
          filter:{
            frontmatter:{
              posttype:{
                eq: "blog"
              }
            }
          }
          ){
          edges{
            node{
              frontmatter{
                title
                
              }
              fields{
                slug
              }
              html
            }
          }
        }
      } 
    `)

    
    return(
        <div className="blog" id="blog">
            <div className="inner">
                <header className="major">
                    <h2>آخرین اخبار</h2>
                </header>
            </div>
            <section  >
                {data.allMarkdownRemark.edges.map((edge, index) => {
                    return(
                        <article className="news" key={index}>
                           {/* <div className="news-cell">
                               <Img fluid = {edge.node.frontmatter.featuredImage.childImageSharp.fluid} />
                            </div> */}
                            <div className="news-cell">
                              <Link to={`blog/${edge.node.fields.slug}`} > 
                                <header>
                                    <h4>{edge.node.frontmatter.title}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: edge.node.html }} />
                                    <br/>
                                </header>
                              </Link>
                            </div>
                        </article>
                    )
                })}
            </section>
        </div>
    )
}
export default Blog