import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>مهاجرت، تحصیل، کار و سفر به کانادا</h1>
            </header>
            <div className="content">
            <h2>در این مسیر همراه شما هستیم</h2>
            <ul className="icons">
                <li><a href="https://www.facebook.com/search/top?q=Farzan%20Immigration%20Services" target="_blank" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.instagram.com/farzanimmigrationservices/" target="_blank" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
                <li><a href="/immigrationForm" className="button">فرم ارزیابی مهاجرت</a></li>
            </ul>
            </div>
        </div>
    </section>
)

export default Banner