import React, {useState} from 'react'
import axios from 'axios'
import * as formValidator from '../assets/utils/formValidate'

const Contact = () => {
    const [data, setData] = useState({
        name:"",
        cellphone:"",
        email:"",
        message:""
    });
    const [formError, setFormError] = useState()
    const [emailError, setEmailError] = useState()
    const [status, setStatus] = useState()

    const {name, cellphone, email, message} = data

    const onChange = e => {
        e.preventDefault();
        setData({ ...data, [e.target.name] : e.target.value})
    }

    const onChangeEmail = e => {
        e.preventDefault();
        setData({ ...data, email:e.target.value })
        if (!formValidator.emailFormat(e.target.value))
        {
            setEmailError('ایمیل شما معتبر نیست')
        } else {
            setEmailError();
        }
    }

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const submitForm = (e) => {
        
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...data })
          })
        .then(() =>  setStatus(true))
        .catch(error => {alert(error); setStatus(false)});
    }

    const validateForm = e => {
        e.preventDefault();
        if(name === "" || email=== "" || message === ""){
            setFormError('* پر کردن این بخش الزامی است')
        } else {
            submitForm()
        }
        
    }

    return (
        <section id="two" className="major">
            <div className="dark-layout"></div>
            <div className="inner">
                <header className="major">
                    <h2>مشاوره مهاجرتی تخصصی</h2>
                </header>
                <div className="grid-wrapper">
                    <div className="col-6">
                       
                    </div>
                    <div className="col-6">
                        <form method="post" data-netlify="true" netlify-honeypot="bot-field" name="contact">
                        <input type="hidden" name="form-name" value="contact" />
                            <div className="grid-wrapper send_direction">
                                <div className="col-10">
                                    <div className="mb-5">
                                        <input type="text" name="name" onChange={e => onChange(e)} placeholder="نام" />
                                        {name ==="" && <div className="error">{formError}</div>}
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="mb-5"><input type="text" name="cellphone" onChange={e => onChange(e)} placeholder="تلفن همراه" /></div>
                                </div>
                                <div className="col-10">
                                    <div className="mb-5">
                                        <input type="email" name="email" onChange={e => onChangeEmail(e)} placeholder="ایمیل" />
                                        {email ==="" && <div className="error">{formError}</div>}
                                        {emailError !== "" && <div className="error">{emailError}</div>}
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="mb-5">
                                        <textarea name="message" onChange={e => onChange(e)} placeholder="موضوع مشاوره" rows="2"></textarea>
                                        {message ==="" && <div className="error">{formError}</div>}
                                    </div>
                                </div>
                                {!status ? 
                                <div className="col-5">
                                    <input type="submit" value="ارسال" className="special fit" onClick={validateForm} />
                                </div> : 
                                <div className="col-10 button fit disabled">
                                    <span >مشاورین ما با شما تماس می گیرند</span>
                                </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
