import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import Blog from '../components/Blog'
import Contact from '../components/Contact'

const HomeIndex = () => {

    const data = useStaticQuery(graphql`
        query{
            allMarkdownRemark(
                filter: {
                    frontmatter: {
                        posttype: {
                            eq: "category"
                        }
                    }
                }
            ) {
                edges{
                    node{
                        frontmatter{
                            title
                            content
                        }
                        fields{
                            slug
                        }
                    }
                }

            }
        }
    `)
        
    return (
        <Layout>
            <Helmet
                title=" خدمات مهاجرتی فرزان - صفحه اصلی "
                meta={[
                    { name: 'description', content: ' دکتر فرزان سجودی وکیل با سابقه مهاجرت، همراه شما در مهاجرت به کانادا  ' }
                ]}
            >
            </Helmet>

            <Banner />

            <div id="main">
                <section id="one" className="tiles">
                    {data.allMarkdownRemark.edges.map((edge, index) => {
                        return (
                            <article key={index}>
                                <header className="major">
                                    <h3>{edge.node.frontmatter.title}</h3>
                                    
                                </header>
                                <div className="content">
                                    <p>{edge.node.frontmatter.content}</p>
                                </div>
                                <br />
                                <br />
                                <Link to={`/${edge.node.fields.slug}`} className="link primary">اطلاعات بیشتر</Link>
                            </article>
                        )
                    })}
                </section>
                <Contact />
                <Blog />
            </div>

        </Layout>
    )

}

export default HomeIndex